import React, { ReactNode } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import BaseComponent from '../../base/base-component';
import EmbedModal from '../embed-modal/embed-modal';
import SolidButton from '../solid-button/solid-button';
import { SolidButtonColorSchemas } from '../solid-button/solid-button-enum';
import SvgIcon from '../svg-icon/svg-icon';
import { SvgIconPathCommand } from '../svg-icon/svg-icon-enum';
import { IShareProperties, IShareState } from './share-interface';
import './share.scss';

export default class Share extends BaseComponent<
  IShareProperties,
  IShareState
> {
  private wrapperRef: React.RefObject<any>;
  public constructor(properties: IShareProperties) {
    super(properties);

    this.state = {
      isShareBoxOpen: false,
      isEmbedModelOpen: false,
      copyLinkText: this.translate('copy'),
    };

    this.onEmbedButtonClick = this.onEmbedButtonClick.bind(this);

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  private handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        isShareBoxOpen: false,
      });
    }
  }

  protected declareTranslateCollection(): string | undefined {
    return 'share';
  }

  private onShareButtonClick(): void {
    this.setState({
      isShareBoxOpen: !this.state.isShareBoxOpen,
    });
  }

  private onCopyClick(): void {
    const { absoluteLink } = this.props;
    const copyLink = `${window.location.protocol}//${window.location.host}${absoluteLink}`;
    navigator.clipboard.writeText(copyLink).then((): void => {
      this.setState({
        copyLinkText: this.translate('copied'),
      });
    });
  }

  private onEmbedButtonClick(): void {
    this.setState({
      isEmbedModelOpen: !this.state.isEmbedModelOpen,
    });
  }

  protected getResourcesPath(): string {
    return '';
  }

  private getShareBox(): ReactNode {
    const {
      description,
      absoluteLink,
      embeddedLink,
      isExpanded = false,
    } = this.props;
    const { isShareBoxOpen } = this.state;
    let publicLink = '';

    //check if window defined.(prevent issue on SSR)
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
      publicLink = `${window.location.protocol}//${window.location.host}${absoluteLink}`;
    }

    const shareBoxClassName: string = [
      isExpanded ? 'c-share__box-expanded' : 'c-share__box',
      isShareBoxOpen || isExpanded ? 'is-show' : 'is-hide',
    ].join(' ');

    return (
      <div className={shareBoxClassName}>
        <div className="c-share-arrow"></div>
        <FacebookShareButton url={publicLink} quote={description}>
          <SvgIcon
            width="24"
            height="24"
            viewBox="0 0 24 24"
            paths={[{ command: SvgIconPathCommand.FACEBOOK, fill: '#1877F2' }]}
          />
          <span>Facebook</span>
        </FacebookShareButton>
        <TwitterShareButton url={publicLink}>
          <SvgIcon
            width="24"
            height="24"
            viewBox="0 0 24 24"
            paths={[{ command: SvgIconPathCommand.TWITTER, fill: '#1DA1F2' }]}
          />
          <span>Twitter</span>
        </TwitterShareButton>
        <WhatsappShareButton url={publicLink}>
          <SvgIcon
            width="24"
            height="24"
            viewBox="0 0 24 24"
            paths={[
              {
                command: SvgIconPathCommand.WHATSAPP,
                fill: '#25D366',
                fillRule: 'evenodd',
                clipRule: 'evenodd',
              },
            ]}
          />
          <span>WhatsApp</span>
        </WhatsappShareButton>
        <button
          className="react-share__ShareButton is-copy"
          onClick={(): void => {
            this.onCopyClick();
          }}
        >
          <SvgIcon
            width="24"
            height="24"
            viewBox="0 0 24 24"
            paths={[
              {
                command: SvgIconPathCommand.COPY,
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                fill: '#000',
              },
            ]}
          />
          <span>{this.state.copyLinkText}</span>
        </button>
        {embeddedLink ? (
          <button
            className="react-share__ShareButton"
            onClick={(): void => {
              this.onEmbedButtonClick();
            }}
          >
            <SvgIcon
              width="24"
              height="24"
              viewBox="0 0 24 24"
              paths={[
                {
                  command: SvgIconPathCommand.EMBED,
                  fillRule: 'evenodd',
                  clipRule: 'evenodd',
                  fill: '#000',
                },
              ]}
            />
            <span>{this.translate('embed')}</span>
          </button>
        ) : (
          <></>
        )}
      </div>
    );
  }

  public render(): ReactNode {
    const { imageResourceFileName, embeddedLink, isExpanded } = this.props;

    return (
      <div className="c-share" ref={this.wrapperRef}>
        {!isExpanded && (
          <>
            <EmbedModal
              closeEmbedModal={this.onEmbedButtonClick}
              showModal={this.state.isEmbedModelOpen}
              embeddedLink={embeddedLink}
              imageSource={`${this.getResourcesPath()}/${imageResourceFileName}`}
            />
            <SolidButton
              colorSchema={SolidButtonColorSchemas.TRANSPARENT}
              onClick={(): void => {
                this.onShareButtonClick();
              }}
            >
              {this.translate('share')}
              <SvgIcon
                width="20"
                viewBox="0 0 20 21"
                paths={[{ command: SvgIconPathCommand.SHARE, fill: '#4A1D96' }]}
              />
            </SolidButton>
          </>
        )}

        {this.getShareBox()}
      </div>
    );
  }
}
